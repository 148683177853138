import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import {Woodworking} from './pages/Woodworking';
import Knitting from "./pages/Knitting";
import ImageGallery from "./pages/ImageGallery/ImageGallery";
import CreateProject from "./pages/CreateWoodwork";
import {Amplify} from "aws-amplify";
import awsExports from "./aws-exports";
import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import './amplify-custom.css';
import './App.css'
import CreateKnitting from "./pages/CreateKnitting";

Amplify.configure(awsExports);


const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
                <Header/>
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/woodworking" element={<Woodworking/>}/>
                        <Route path="/knitting" element={<Knitting/>}/>
                        <Route path="/gallery" element={<ImageGallery category="knitting"/>}/>
                        <Route path="/createWoodworking" element={<Authenticator><CreateProject/></Authenticator>}/>
                        <Route path="/createKnitting" element={<Authenticator><CreateKnitting/></Authenticator>}/>
                        {/* Add other routes here */}
                    </Routes>
                </div>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;