import React, {useEffect, useState} from "react";
import {fetchFullImage} from "../../api";
import './ImageOverlay.css';

interface aiProps {
    id: string
    category: string
}

const ImageOverlay: React.FC<aiProps> = (props) => {

    const [aiImage, setAiImage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [score, setScore] = useState<number>(5); // Default score of 5

    const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScore(Number(event.target.value));
    };

    const handleSubmit = () => {

    };

    useEffect(() => {

        const fetchImage = async () => {
            try {
                const response = await fetchFullImage(props.category, props.id);
                // Convert ArrayBuffer to Base64 string
                const base64String = arrayBufferToBase64(response);
                // Set the Base64 string as the image source
                setAiImage(`data:image/png;base64,${base64String}`);
            } catch (error) {
                setError('Error fetching the image:' + error);
            } finally {
                setLoading(false);
            }
        };

        fetchImage();

    }, []);

    const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };


    if (loading) return (
        <div className="overlay">
            <div className="spinner"></div>
            <div className="progress-box">
                <p>Loading...</p>
            </div>
        </div>
    )
    if (error) return <p>{error}</p>;
    return (
        <div className="overlay-content">
            <img className="overlay-image" src={aiImage}  alt="Full screen" width="1024" height="1024"/>
            <div className="score-input">
                <label htmlFor="score">Score the design (1-10): </label>
                <input
                    id="score"
                    type="range"
                    min="1"
                    max="10"
                    value={score}
                    onChange={handleScoreChange}
                />
                <span>{score}</span>
            </div>
            <button className="submit-button" onClick={handleSubmit}>
                Submit Score
            </button>
        </div>
    )
};

export default ImageOverlay;