import React from 'react';
import './ProjectCard.css';
import {Link} from "react-router-dom";

interface ProjectCardProps {
    title: string;
    description: string;
    imageUrl?: string; // Optional: Image URL for the project card
    linkUrl: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({title, description, imageUrl, linkUrl}) => {
    return (
        <div className="card">
            <Link to={linkUrl}>
                {imageUrl && <img src={imageUrl} alt={title}/>}
                <div className="card-content">
                    <div className="card-title">{title}</div>
                    <div className="card-description">{description}</div>
                </div>
            </Link>
        </div>
    );
};

export default ProjectCard;