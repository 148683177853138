import React from 'react';
import ImageGallery from "./ImageGallery/ImageGallery";
import styled from "styled-components";

const PageContainer = styled.div`
  padding: 20px;
`;

const Knitting: React.FC = () => {

    return (
        <section className="section" id="knitting">
            <h2>AI-Enhanced Knitting</h2>
            <p>Discover how AI can enhance your knitting projects.</p>
            <ImageGallery category="knitting"/>
        </section>
    )
};

export default Knitting;