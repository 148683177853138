import React from 'react';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import WoodworkingLogo from '../../ibzt3s8h.png';
import KnittingLogo from '../../knitting.png';
import TutorialsLogo from '../../Tutorials.png';
import KnittingProject from '../../knittingProject.png';
import './Home.css';


const Home: React.FC = () => (
    <section className="section" id="home">
        <div className="cards-container">
            <ProjectCard title="Woodworking with CNC" description="Precision craftsmanship with CNC machines."
                         imageUrl={WoodworkingLogo} linkUrl="/woodworking"/>
            <ProjectCard title="AI-Enhanced Knitting" description="Creating intricate designs with AI."
                         imageUrl={KnittingProject} linkUrl="/knitting"/>
            <ProjectCard title="Tutorials" description="Learn to empower your creativity" imageUrl={TutorialsLogo} linkUrl="/"/>
            <ProjectCard title="Products" description="Visit our product catalog" imageUrl={KnittingLogo} linkUrl="/"/>
        </div>
    </section>
);

export default Home;