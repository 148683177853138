import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './Header.css'
import {checkUserAuthentication} from "../../amplify-api";
import NerdicLogo from '../../logo.png';


const Header: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<any>(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        async function checkAuth() {
            try {
                const currentUser = await checkUserAuthentication();
                setIsAuthenticated(true);
                setUser(currentUser);
            } catch (error) {
                setIsAuthenticated(false);
            }
        }

        checkAuth();
    }, []);


    return (
        <header className="header">
            <div className="logo-section">
                <img src={NerdicLogo} alt="Logo" className="logo"/>
                <h1 className="site-name">Nerdic's Workshop</h1>
            </div>
            <div className="subheader">

                {/* Subheader links */}
                <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
                    <Link to="/">Home</Link>
                    <Link to="/woodworking">Woodworking</Link>
                    <Link to="/knitting">Knitting</Link>
                    <Link to="/tutorials">Tutorials</Link>
                    <Link to="/createWoodworking">Woodworking Design</Link>
                    <Link to="/createKnitting">Knitting Design</Link>
                </nav>
            </div>
            <div className="login-info">
                {isAuthenticated ? (
                    <div className="user-info">
                        <span>{user.name}</span>
                        <Link to="/logout" className="logout-link">Logout</Link>
                    </div>
                ) : (
                    <Link to="/login" className="login-link">Login</Link>
                )}
            </div>
            {/* Hamburger Menu Icon */}
            <div className="hamburger" onClick={toggleMenu}>
                <span className={menuOpen ? 'hamburger-icon open' : 'hamburger-icon'}></span>
            </div>
        </header>
    )
};

export default Header;