import {ResourcesConfig} from '@aws-amplify/core';

const resourcesConfig: ResourcesConfig = {
    Auth: {

        Cognito: {
            userPoolClientId: "30u8janl4esqsdpibnf9ipuv2d",
            userPoolId: "eu-north-1_SkDJUyetn",
            loginWith: {
                oauth: {
                    domain: "https://nerdic.auth.eu-north-1.amazoncognito.com",
                    scopes: ["email", "profile", "openid"],
                    redirectSignIn: ["http://localhost:3000/"],
                    redirectSignOut: ["http://localhost:3000/"],
                    responseType: "code"
                }
            }
        }
    }
};

export default resourcesConfig;