import React from 'react';
import './Footer.css'

const Footer: React.FC = () => (
    <div className="footer">
        <p>© 2024 Nerdic's Workshop. All rights reserved.</p>
        <p>Follow us on social media: [Links]</p>
    </div>
);

export default Footer;