import {fetchAuthSession} from "aws-amplify/auth";


export async function getJwtToken(): Promise<string | undefined> {
    try {
        const session = await fetchAuthSession();
        return session.tokens?.idToken?.toString()
    } catch (error) {
        console.error('Error retrieving token:', error);
        return undefined;
    }
}

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};
