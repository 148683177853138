import React, {useEffect, useState} from 'react';
import {fetchThumbnail, listImages} from "../../api";
import './ImageGallery.css';
import ImageOverlay from "../ImageOverlay/ImageOverlay";

interface Image {
    id: string;
    url: string;
    base64: string;
}

interface ImageGalleryProps {
    category: string;
}

const ImageGallery: React.FC<ImageGalleryProps> = (props) => {
    const [thumbnails, setThumbnails] = useState<Image[]>([]);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const openOverlay = async (image: Image) => {
        console.log("Opening overlay...")

        setSelectedImage(image.id);
    };

    const closeOverlay = () => {
        setSelectedImage(null);
    };

    // Function to add a new item to the array
    const addThumbnail = (thumbnail: Image) => {
        // Update the state by creating a new array and adding the new item
        setThumbnails(prevItems => [...prevItems, thumbnail]);
    };

    useEffect(() => {
        // Fetch the image URLs from the backend
        const fetchImages = async () => {
            const response = await listImages(props.category); // assuming the backend is running on the same domain
            response.urls.map((key: string) =>
                fetchImage(key, props.category)
            )
        };

        fetchImages();
    }, []);

    const fetchImage = async (key: string, category: string) => {
        if (key !== undefined && key.length > 0) {
            const response = await fetchThumbnail(key, category);
            // Convert ArrayBuffer to Base64 string
            const base64String = arrayBufferToBase64(response);
            // Set the Base64 string as the image source
            addThumbnail({id: key, url: '', base64: `data:image/png;base64,${base64String}`});
        }
    };


    const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    return (
        <div>
            <div className="gallery">

                {thumbnails.map((thumbnail, index) => (
                    <div key={index} className="gallery-item">
                        <img src={thumbnail.base64} alt={`AI Image ${index}`} onClick={() => openOverlay(thumbnail)}/>
                    </div>
                ))}

            </div>
            {selectedImage && (
                <div className="overlay" onClick={closeOverlay}>
                    <span className="close">&times;</span>
                    <ImageOverlay category={props.category} id={selectedImage}/>
                </div>
            )}
        </div>
    );
};

export default ImageGallery;