import React, {useState} from 'react';
import BaseForm from '../BaseForm/BaseForm';

interface KnittingFormState {
    projectType: string;
    briefDescription: string;
    style: string;
    group: string;
    colors: string;
    pattern: string;
    size: string;
    woolType: string;
    neck: string;
    complexity: string;
    additionalNotes: string;
}


const KnittingForm: React.FC = () => {
    const [formState, setFormState] = useState<KnittingFormState>({
        projectType: '',
        briefDescription: '',
        style: '',
        group: '',
        colors: '',
        pattern: '',
        size: '',
        woolType: '',
        neck: '',
        complexity: '',
        additionalNotes: '',
    });


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value});
    };

    return (
        <BaseForm<KnittingFormState> formData={formState} projectType="knitting">
            {/* Custom HTML or fields can be inserted here */}

            <label htmlFor="projectType">Type of project:</label>
            <input
                type="text"
                name="projectType"
                id="projectType"
                placeholder="e.g. Sweater, Hat"
                value={formState.projectType}
                onChange={handleInputChange}
                required
            />
            {/* Additional Notes */}
            <label htmlFor="briefDescription">Brief Description:</label>
            <textarea
                name="briefDescription"
                id="briefDescription"
                placeholder="Give a brief description of the project..."
                value={formState.briefDescription}
                onChange={handleInputChange}
            />
            {/* Style */}
            <label htmlFor="style">Style:</label>
            <input
                type="text"
                name="style"
                id="style"
                placeholder="e.g. Marius, Faroese, Italian"
                value={formState.style}
                onChange={handleInputChange}
                required
            />

            {/* Group */}
            <label htmlFor="complexity">Intended for:</label>
            <select
                name="group"
                id="group"
                value={formState.group}
                onChange={handleInputChange}
                required
            >
                <option value="">Select type</option>
                <option value="Man">Man</option>
                <option value="Woman">Woman</option>
                <option value="Child">Child</option>
                <option value="Baby">Baby</option>
            </select>

            {/* Colors */}
            <label htmlFor="colors">Colors:</label>
            <input
                type="text"
                name="colors"
                id="colors"
                placeholder="e.g. Red, Blue, Grey"
                value={formState.colors}
                onChange={handleInputChange}
                required
            />

            {/* Period */}
            <label htmlFor="pattern">Pattern:</label>
            <input
                type="text"
                name="pattern"
                id="pattern"
                placeholder="e.g. Ribbed, Stockinette, Cable Knit"
                value={formState.pattern}
                onChange={handleInputChange}
                required
            />

            {/* Size */}
            <label htmlFor="size">Size:</label>
            <select
                name="size"
                id="size"
                value={formState.size}
                onChange={handleInputChange}
                required
            >
                <option value="">Select Size</option>
                <option value="Small">Small</option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
                <option value="X-Large">X-Large</option>
            </select>

            {/* Wood Type */}
            <label htmlFor="woolType">Wool Type:</label>
            <select
                name="woolType"
                id="woolType"
                value={formState.woolType}
                onChange={handleInputChange}
                required
            >
                <option value="">Select Wool Type</option>
                <option value="Thick">Thick</option>
                <option value="Medium">Medium</option>
                <option value="Fine">Fine</option>
            </select>

            {/* Complexity */}
            <label htmlFor="complexity">Complexity Level:</label>
            <select
                name="complexity"
                id="complexity"
                value={formState.complexity}
                onChange={handleInputChange}
                required
            >
                <option value="">Select Complexity</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
            </select>

            {/* Additional Notes */}
            <label htmlFor="additionalNotes">Additional Notes:</label>
            <textarea
                name="additionalNotes"
                id="additionalNotes"
                placeholder="Include any specific requirements or notes..."
                value={formState.additionalNotes}
                onChange={handleInputChange}
            />

        </BaseForm>
    );
};

export default KnittingForm;