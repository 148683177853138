import {fetchUserAttributes} from "aws-amplify/auth";
import {Amplify} from "aws-amplify";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

export const checkUserAuthentication = async () => {
    const user = await fetchUserAttributes()
    console.log('User is authenticated:', user);
    return user;
}