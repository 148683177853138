import React from 'react';
import BaseKnitting from "../components/KnittingProject/BaseKnitting";

const App: React.FC = () => {
    return (
        <div>
            <BaseKnitting/>
        </div>
    );
};

export default App;