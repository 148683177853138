import React from 'react';
import ImageGallery from "./ImageGallery/ImageGallery";

const Woodworking: React.FC = () => (
    <section className="section" id="woodworking">
        <h2>Woodworking with CNC</h2>
        <p>Explore projects, tutorials, and tools for CNC woodworking.</p>
        <ImageGallery category="woodworking"/>
    </section>
);

export {Woodworking};