import React, {useState} from 'react';
import BaseForm from '../BaseForm/BaseForm';

interface CNCWoodworkingFormState {
    projectType: string;
    environment: string;
    style: string;
    colors: string;
    period: string;
    length: number;
    width: number;
    height: number;
    woodType: string;
    complexity: string;
    additionalNotes: string;
}


const WoodworkingForm: React.FC = () => {
    const [formState, setFormState] = useState<CNCWoodworkingFormState>({
        projectType: '',
        environment: '',
        style: '',
        colors: '',
        period: '',
        length: 0,
        width: 0,
        height: 0,
        woodType: '',
        complexity: '',
        additionalNotes: '',
    });


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value});
    };

    return (
        <BaseForm<CNCWoodworkingFormState> formData={formState} projectType="woodworking">
            {/* Custom HTML or fields can be inserted here */}

                <label htmlFor="projectType">Type of project:</label>
                <input
                    type="text"
                    name="projectType"
                    id="projectType"
                    placeholder="e.g. Chair, Desk, Sign"
                    value={formState.projectType}
                    onChange={handleInputChange}
                    required
                />

                <label htmlFor="environment">Environment for project:</label>
                <input
                    type="text"
                    name="environment"
                    id="environment"
                    placeholder="e.g. Office, Home, Industrial etc"
                    value={formState.environment}
                    onChange={handleInputChange}
                    required
                />
                {/* Style */}
                <label htmlFor="style">Style:</label>
                <input
                    type="text"
                    name="style"
                    id="style"
                    placeholder="e.g. Rustic, Modern, Traditional"
                    value={formState.style}
                    onChange={handleInputChange}
                    required
                />

                {/* Colors */}
                <label htmlFor="colors">Colors:</label>
                <input
                    type="text"
                    name="colors"
                    id="colors"
                    placeholder="e.g. Walnut, Cherry, Maple"
                    value={formState.colors}
                    onChange={handleInputChange}
                    required
                />

                {/* Period */}
                <label htmlFor="period">Period:</label>
                <input
                    type="text"
                    name="period"
                    id="period"
                    placeholder="e.g. Victorian, Mid-century"
                    value={formState.period}
                    onChange={handleInputChange}
                    required
                />

                {/* Size */}
                <label>Dimensions (in cm):</label>
                <div className="dimension-inputs">
                    <input
                        type="number"
                        name="length"
                        placeholder="Length"
                        value={formState.length}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="number"
                        name="width"
                        placeholder="Width"
                        value={formState.width}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="number"
                        name="height"
                        placeholder="Height"
                        value={formState.height}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                {/* Wood Type */}
                <label htmlFor="woodType">Wood Type:</label>
                <select
                    name="woodType"
                    id="woodType"
                    value={formState.woodType}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Select Wood Type</option>
                    <option value="Oak">Oak</option>
                    <option value="Maple">Maple</option>
                    <option value="Cherry">Cherry</option>
                    <option value="Walnut">Walnut</option>
                    <option value="Pine">Pine</option>
                    <option value="Birch">Birch</option>
                </select>

                {/* Complexity */}
                <label htmlFor="complexity">Complexity Level:</label>
                <select
                    name="complexity"
                    id="complexity"
                    value={formState.complexity}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Select Complexity</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                </select>

                {/* Additional Notes */}
                <label htmlFor="additionalNotes">Additional Notes:</label>
                <textarea
                    name="additionalNotes"
                    id="additionalNotes"
                    placeholder="Include any specific requirements or notes..."
                    value={formState.additionalNotes}
                    onChange={handleInputChange}
                />

        </BaseForm>
    );
};

export default WoodworkingForm;